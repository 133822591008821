.selectContainer {
  position: relative;
}

.searchInput {
  width: 100%;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  background: no-repeat right 1rem center;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 0.5rem;
  max-height: 300px;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.recipientItem {
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.recipientItem:hover {
  background-color: #f5f5f5;
}

.recipientAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.recipientInfo {
  display: flex;
  flex-direction: column;
}

.recipientName {
  font-weight: 500;
}

.recipientPhone {
  color: #666;
  font-size: 0.9rem;
}

.editLink {
  margin-top: 0.5rem;
}

.editLink a {
  color: #00AE5A;
  text-decoration: none;
  font-size: 0.9rem;
}

.editLink a:hover {
  text-decoration: underline;
}