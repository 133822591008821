.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 1rem;
}

.modalContent {
  background: white;
  border-radius: 24px;
  padding: 2rem;
  width: 100%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.modalHeader h2 {
  font-size: clamp(1.25rem, 4vw, 1.5rem);
  font-weight: 600;
}

.closeButton {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  margin: -0.5rem;
}

.modalSubtext {
  color: #666;
  margin-bottom: 1.5rem;
  font-size: clamp(0.875rem, 3vw, 1rem);
}

.createForm {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.formRow {
  display: flex;
  gap: 1rem;
}

.formGroup {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.formGroup label {
  font-weight: 500;
  font-size: clamp(0.875rem, 3vw, 1rem);
}

.formGroup input {
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: clamp(0.875rem, 3vw, 1rem);
  width: 100%;
}

.phoneInput {
  display: flex;
  gap: 0.5rem;
}

.countryCode {
  width: 110px;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: white;
  font-size: clamp(0.875rem, 3vw, 1rem);
}

.modalActions {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

.cancelButton,
.createButton {
  flex: 1;
  padding: 0.875rem;
  border-radius: 8px;
  cursor: pointer;
  font-size: clamp(0.875rem, 3vw, 1rem);
  min-height: 48px;
}

.cancelButton {
  border: 1px solid #ddd;
  background: white;
}

.createButton {
  border: none;
  background: #00ae5a;
  color: white;
}

@media (max-width: 768px) {
  .modalContent {
    padding: 1.5rem;
    margin: 0;
    border-radius: 16px;
  }

  .formRow {
    flex-direction: column;
    gap: 1.25rem;
  }
}

@media (max-width: 480px) {
  .modalOverlay {
    padding: 0.5rem;
  }

  .modalContent {
    padding: 1.25rem;
    border-radius: 12px;
  }

  .modalActions {
    flex-direction: column;
  }

  .phoneInput {
    flex-direction: column;
  }

  .countryCode {
    width: 100%;
  }
}

/* Custom scrollbar for the modal */
.modalContent::-webkit-scrollbar {
  width: 8px;
}

.modalContent::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.modalContent::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.modalContent::-webkit-scrollbar-thumb:hover {
  background: #666;
}

.countrySelect {
  width: 100%;
  padding: 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 16px;
  color: #1a202c;
  background-color: white;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23666666%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.4-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 12px;
}

.countrySelect:focus {
  outline: none;
  border-color: #4299e1;
  box-shadow: 0 0 0 1px #4299e1;
}
