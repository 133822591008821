.lipaworld_container {
  position: relative;
}

.lipaworld_secondaryNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 2rem;
  border-bottom: 1px solid #eee;
  background: white;
  position: relative;
}

.lipaworld_desktopNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.lipaworld_mobileMenuContent {
  display: none;
}

.lipaworld_closeButton {
  display: none;
}

.lipaworld_navLinks {
  display: flex;
  gap: 2rem;
}

.lipaworld_navLink {
  text-decoration: none;
  color: #333;
  font-size: 0.9rem;
  padding: 0.25rem 0;
  position: relative;
}

.lipaworld_navLink:hover {
  color: #000;
}

.lipaworld_navLink:hover::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #333;
}

.lipaworld_localeSelector {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.lipaworld_localeButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  border-radius: 10px;
  background: white;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 0.9rem;
  font-weight: 500;
  color: #333;
}

.lipaworld_flagIcon {
  width: 20px;
  height: 15px;
  object-fit: cover;
}

/* Mobile Styles */
.lipaworld_mobileNav {
  display: none;
}

@media (max-width: 768px) {
  .lipaworld_desktopNav {
    display: none;
  }

  .lipaworld_mobileNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .lipaworld_hamburgerButton {
    background: none;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    z-index: 1001;
    margin: 0;
    padding: 0;
  }

  .lipaworld_hamburgerIcon {
    display: block;
    width: 24px;
    height: 2px;
    background: #333;
    position: relative;
  }

  .lipaworld_hamburgerIcon::before,
  .lipaworld_hamburgerIcon::after {
    content: "";
    position: absolute;
    width: 24px;
    height: 2px;
    background: #333;
    left: 0;
  }

  .lipaworld_hamburgerIcon::before {
    top: -6px;
  }

  .lipaworld_hamburgerIcon::after {
    bottom: -6px;
  }

  .lipaworld_mobileMenu {
    position: fixed;
    top: 0;
    left: 0;
    width: 80%;
    height: 100vh;
    background: white;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 1001;
    overflow-y: auto;
  }

  .lipaworld_mobileMenu.open {
    transform: translateX(0);
  }

  .lipaworld_mobileMenuContent {
    display: block;
    padding: 1rem;
  }

  .lipaworld_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  .lipaworld_mobileMenuHeader {
    padding: 1rem;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: flex-end;
  }

  .lipaworld_closeButton {
    display: block; /* Explicitly show the close button on mobile */
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #333;
    cursor: pointer;
    padding: 0.5rem;
  }

  .lipaworld_mobileMenuItem {
    display: block;
    padding: 1rem;
    text-decoration: none;
    color: #333;
    border-bottom: 1px solid #eee;
  }

  .lipaworld_mobileMenuItem:last-child {
    border-bottom: none;
  }
}
