.cartHolder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  gap: 12px;
}

.buyNowButton {
  width: 100%;
  padding: 0.5rem;
  background-color: #16a34a;
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  cursor: pointer;
  font-size: 0.875rem;
}

.cart {
  background-color: #0fbc49;
  color: #ffffff;
  padding: 4px 8px;
  border: 0;
  border-radius: 4px;
  height: 36px;
  cursor: pointer;
  min-width: 100px;
}

.wish {
  background-color: black;
  color: #ffffff;
  padding: 4px 8px;
  border: 0;
  border-radius: 4px;
  height: 36px;
  cursor: pointer;
  min-width: 100px;
  margin-top: 10px;
}

.cart:disabled {
  color: #ffffff;
  background-color: lightslategrey;
  padding: 4px 8px;
  border: 0;
  border-radius: 4px;
  height: 36px;
  cursor: pointer;
  min-width: 100px;
}

.cartAdded {
  background-color: #ffffff;
  color: darkslategrey;
  padding: 4px 8px;
  border: 1px solid darkslategrey;
  border-radius: 4px;
  height: 36px;
  cursor: pointer;
  min-width: 100px;
}

.cart:active {
  background-color: #0fbc3dc7;
  color: #fff;
  padding: 4px 8px;
  border: 0;
  border-radius: 4px;
  height: 35px;
  box-shadow: 0 0 0 0.1rem #0b6d25c7;
  cursor: pointer;
}

.deleteIcon {
  cursor: pointer;
}

.plus {
  font-weight: 600;
  font-size: 26px;
  color: #808080;
}

.createList {
  color: #0fbc49;
  display: flex;
  align-items: center;
  gap: 4px;

  div:first-child {
    font-size: 18px;
  }

  div:nth-child(2) {
    padding-top: 3.4px;
  }
}
