.profileContainer {
    padding: 32px;
    max-width: 1200px;
    margin: 0 auto;
}

.container {
    display: flex;
    min-height: 100vh;
    background-color: #f8f9fa;
    padding: 20px;
    gap: 24px;
}

.h1 {
    color: var(--Text-Dark, #010E1F);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 155.556% */
}

.mainContent {
    flex: 1;
}

/* .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    gap: 24px;
    margin-top: 24px;
} */

.mainTitle {
    font-size: 32px;
    font-weight: 600;
    color: #101828;
    margin-bottom: 24px;
}

.profileCard {
    background: white;
    border-radius: 24px;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
    padding: 32px;
}

.cardTitle {
    font-size: 24px;
    font-weight: 600;
    color: #101828;
    margin-bottom: 32px;
}

.profileContent {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 40px;
}

.avatarSection {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 32px;
}

.avatarCircle {
    width: 96px;
    height: 96px;
    background: #8D93E8;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    color: #101828;
    font-weight: 500;
}

.avatarCircle span {
    color: white;
}

.uploadSection {
    display: flex;
    gap: 8px;
}

.uploadButton {
    padding: 8px 16px;
    background: white;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    color: #344054;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
}

.deleteButton {
    padding: 8px;
    background: white;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    color: #F04438;
    cursor: pointer;
}

.infoSection {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.infoRow {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.fieldLabel {
    font-size: 14px;
    color: #98A2B3;
}

.fieldContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.fieldValue {
    font-size: 16px;
    color: #101828;
    font-weight: 600;
}

.changeButton {
    padding: 8px 16px;
    background: white;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    color: #344054;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
}

.verifiedBadge {
    padding: 4px 12px;
    background: #ECFDF3;
    border-radius: 16px;
    color: #027A48;
    font-size: 14px;
    font-weight: 500;
}

.unverifiedBadge {
    background: #FEF3F2;
    color: #B42318;
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 14px;
    font-weight: 500;
}

.phoneNumber, .country {
    display: flex;
    align-items: center;
    gap: 8px;
}

.flag {
    font-size: 16px;
}

.actionButtons {
    display: flex;
    align-items: center;
    gap: 8px;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    background: white;
    border-radius: 12px;
    width: 100%;
    max-width: 500px;
    margin: 20px;
}

.modalHeader {
    padding: 24px;
    border-bottom: 1px solid #EAECF0;
    position: relative;
}

.modalTitle {
    font-size: 18px;
    font-weight: 600;
    color: #101828;
    margin: 0;
}

.modalSubtitle {
    font-size: 14px;
    color: #667085;
    margin: 4px 0 0 0;
}

.modalClose {
    position: absolute;
    top: 24px;
    right: 24px;
    background: none;
    border: none;
    font-size: 24px;
    color: #667085;
    cursor: pointer;
}

.modalBody {
    padding: 24px;
}

.formFields {
    display: flex;
    /* flex-direction: column; */
    gap: 20px;
    margin-bottom: 24px;
}

.inputField {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.inputField label {
    font-size: 14px;
    color: #344054;
    font-weight: 500;
}

.inputField input {
    padding: 10px 14px;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    font-size: 16px;
}

.modalActions {
    display: flex;
    gap: 12px;
}

.cancelButton {
    flex: 1;
    padding: 10px;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    background: white;
    color: #344054;
    font-weight: 500;
    cursor: pointer;
}

.continueButton {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 8px;
    background: #66C61C;
    color: white;
    font-weight: 500;
    cursor: pointer;
}

@media (max-width: 640px) {
    .modalContent {
        margin: 0;
        height: 100vh;
        border-radius: 0;
        display: flex;
        flex-direction: column;
    }

    .modalBody {
        flex: 1;
    }

    .modalActions {
        margin-top: auto;
    }
}

@media (max-width: 1024px) {
    .container {
        flex-direction: column;
    }

    .sidebar {
        width: 100%;
        position: static;
    }
}

/* Responsive Styles */
@media (max-width: 768px) {
    .profileContainer {
        padding: 16px;
    }

    .grid {
        grid-template-columns: 1fr;
    }

    .profileCard {
        padding: 24px;
    }

    .mainTitle {
        font-size: 24px;
    }

    .cardTitle {
        font-size: 20px;
    }

    .avatarCircle {
        width: 72px;
        height: 72px;
        font-size: 24px;
    }

    .fieldContent {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
    }

    .actionButtons {
        width: 100%;
        justify-content: space-between;
    }
}

@media (max-width: 480px) {
    .uploadSection {
        flex-direction: column;
    }

    .changeButton {
        width: 100%;
    }

    .avatarSection {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}