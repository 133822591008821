.lipaworld_featuredContainer {
  padding: 2rem;
  max-width: 1440px;
  margin: 0 auto;
  background-color: #f8f8f8;
}

.lipaworld_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.lipaworld_title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1f2937;
}

.lipaworld_navigation {
  display: flex;
  gap: 0.5rem;
}

.lipaworld_navButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #e5e7eb;
  background: white;
  cursor: pointer;
  transition: all 0.2s;
}

.lipaworld_navButton svg {
  fill: #4b5563;
}

.lipaworld_navButton:hover {
  background: #f3f4f6;
}

.lipaworld_navButtonDisabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.lipaworld_navButtonDisabled:hover {
  background: white;
}

.lipaworld_categoriesSlider {
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 0.5rem;
}

.lipaworld_categoriesSlider::-webkit-scrollbar {
  display: none;
}

.lipaworld_categoryCard {
  flex: 0 0 auto;
  width: 160px;
  padding: 1.5rem;
  background: white;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  scroll-snap-align: start;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  cursor: pointer;
}

.lipaworld_categoryCard:hover {
  transform: translateY(-2px);
}

.lipaworld_iconWrapper {
  width: 30px;
  height: 30px;
  background: #eef2ff;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lipaworld_icon {
  object-fit: contain;
}

.lipaworld_categoryName {
  font-size: 0.975rem;
  font-weight: 500;
  color: #374151;
  text-align: center;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .lipaworld_featuredContainer {
    padding: 1rem;
  }

  .lipaworld_categoryCard {
    width: 140px;
    padding: 1rem;
  }

  .lipaworld_iconWrapper {
    width: 40px;
    height: 40px;
  }

  .lipaworld_icon {
    font-size: 1.25rem;
  }

  .lipaworld_categoryName {
    font-size: 0.875rem;
  }
}

@media (max-width: 480px) {
  .lipaworld_header {
    flex-direction: row;
    align-items: flex-start;
    gap: 1rem;
    padding: 1rem;
  }

  .lipaworld_featuredContainer {
    padding: 0;
  }

  .lipaworld_navigation {
    align-self: flex-end;
  }

  .lipaworld_categoryCard {
    width: 120px;
  }
}
