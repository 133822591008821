.progressContainer {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.adjust {
    padding-left: 50px;
    padding-right: 90px;
    padding-top: 3.5px;
}

.step {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-radius: 100px;
    padding: 8px;
    font-family: 'Visby CF', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: #8a96a3;
    border: 1px solid #e2e2e2;
}

.step.active {
    color: #010e1f;
    border-color: #00ae5a;
}

.number {
    display: inline-block;
    width: 15px;
    height: 15px;
    line-height: 14px;
    text-align: center;
    border-radius: 10px;
    color: black;
    margin-right: 7px;
    border-radius: 100px;
    border-radius: 10px;
    border: 1px solid var(--Green-green-600, #00AE5A);
}

.numberInactive {
    display: inline-block;
    width: 15px;
    height: 15px;
    line-height: 14px;
    text-align: center;
    border-radius: 10px;
    color: black;
    margin-right: 7px;
    border-radius: 100px;
    border-radius: 10px;
    color: #8a96a3;
    background-color: transparent;
}

.line1 {
    flex-grow: 1;
    height: 1px;
    background-color: #00ae5a;
    margin: 0 4px;
}

.line {
    flex-grow: 1;
    height: 1px;
    background-color: #e2e2e2;
    margin: 0 4px;
}

.label {
    color: #010e1f;
}

.labelInactive {
    color: #8a96a3;
}

.icon {
    width: 16px;
    height: 16px;
    margin-right: 7px;
}
  
  .lipaworld_backButton svg {
    fill: currentColor;
  }

  .lipaworld_backButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: none;
    border: none;
    font-size: 1rem;
    color: #1F2937;
    cursor: pointer;
    padding: 0;
    margin-bottom: 1.5rem; /* Ensures spacing below the button */
}

.lipaworld_backButton:hover {
    color: #4B5563;
}


@media screen and (max-width: 768px) {
    .adjust {
        padding: 16px;
    }

    .progressContainer {
        padding: 8px 0;
    }
    
    .step {
        padding: 6px 8px;
        font-size: 11px;
    }
    
    /* .number,
    .numberInactive {
        width: 14px;
        height: 14px;
        font-size: 10px;
        margin-right: 4px;
    } */

    .lipaworld_backButton {
        margin: 0;
        margin-bottom: 30px;
        /* margin-top: -20px; */
      }
    
    .line,
    .line1 {
        margin: 0 4px;
    }

    .icon {
        width: 14px;
        height: 14px;
        margin-right: 4px;
    }
}

@media screen and (max-width: 480px) {
    .adjust {
        padding: 12px;
    }

    .progressContainer {
        padding: 4px 0;
    }
    
    .step {
        padding: 4px 6px;
        font-size: 10px;
    }

    .label,
    .labelInactive {
        font-size: 10px;
    }
    
    /* .number,
    .numberInactive {
        width: 12px;
        height: 12px;
        font-size: 9px;
        margin-right: 3px;
    } */
    
    .line,
    .line1 {
        margin: 0 2px;
    }

    .icon {
        width: 12px;
        height: 12px;
        margin-right: 3px;
    }

    .backText {
        font-size: 14px;
    }
}

@media screen and (max-width: 360px) {
    .step {
        padding: 3px 4px;
        font-size: 9px;
    }

    .label,
    .labelInactive {
        font-size: 9px;
    }

    .line,
    .line1 {
        margin: 0 1px;
    }
}