.recipientContainer {
  padding: 32px;
  max-width: 1200px;
  margin: 0 auto;
}

.recipientTitle {
  font-size: 32px;
  font-weight: 600;
  color: #101828;
  margin-bottom: 24px;
}

.tabsContainer {
  display: flex;
  border-bottom: 1px solid #eaecf0;
  margin-bottom: 24px;
}

.tab {
  padding: 12px 24px;
  border: none;
  background: none;
  color: #667085;
  font-size: 16px;
  cursor: pointer;
  position: relative;
}

.activeTab {
  color: #039855;
  font-weight: 500;
}

.activeTab::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 2px;
  background: #039855;
}

.recipientHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.recipientCount {
  font-size: 18px;
  font-weight: 500;
  color: #101828;
  display: flex;
  align-items: center;
  gap: 8px;
}

.countBadge {
  color: #667085;
  font-weight: normal;
}

.recipientActions {
  display: flex;
  gap: 16px;
  align-items: center;
}

.searchContainer {
  position: relative;
}

.searchInput {
  padding: 10px 16px;
  padding-left: 40px;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  width: 280px;
  font-size: 14px;
}

.searchIcon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #667085;
}

.createButton {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  /* background: #039855; */
  color: #039855;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.recipientCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #eaecf0;
}

.recipientInfo {
  display: flex;
  align-items: center;
  gap: 16px;
}

.avatarCircle {
  width: 48px;
  height: 48px;
  background: #e9d7fe;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #101828;
  font-weight: 500;
}

.recipientDetails {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.recipientName {
  font-size: 16px;
  font-weight: 500;
  color: #101828;
  margin: 0;
}

.recipientContact {
  display: flex;
  gap: 16px;
  color: #667085;
  font-size: 14px;
}

.phoneContainer {
  display: flex;
  align-items: center;
  gap: 4px;
}

.recipientControls {
  display: flex;
  align-items: center;
  gap: 12px;
}

.sendMoneyButton {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: white;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  color: #344054;
  font-size: 14px;
  cursor: pointer;
}

.favoriteButton {
  padding: 8px;
  background: white;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  cursor: pointer;
}

.moreButton {
  padding: 8px;
  background: white;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  width: 35px;
}

.dropdownContainer {
  position: relative;
}

.dropdownMenu {
  position: absolute;
  right: 0;
  top: 100%;
  background: white;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  min-width: 200px;
  z-index: 10;
}

.dropdownItem {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 10px 16px;
  border: none;
  background: none;
  text-align: left;
  color: #344054;
  font-size: 14px;
  cursor: pointer;
}

.dropdownItem:hover {
  background: #f9fafb;
}

.container {
  display: flex;
  min-height: 100vh;
  background-color: #f8f9fa;
  padding: 20px;
  gap: 24px;
}

.h1 {
  color: var(--Text-Dark, #010e1f);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 155.556% */
}

.mainContent {
  flex: 1;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  border-radius: 12px;
  width: 100%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  margin: 20px;
  position: relative;
}

.modalHeader {
  padding: 24px;
  border-bottom: 1px solid #eaecf0;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
}

.modalTitle {
  font-size: 24px;
  font-weight: 600;
  color: #101828;
  margin: 0;
}

.modalSubtitle {
  font-size: 14px;
  color: #667085;
  margin: 4px 0 0 0;
}

.modalClose {
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  font-size: 24px;
  color: #667085;
  cursor: pointer;
}

.modalBody {
  padding: 24px;
}

.sendMoneySection {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
  background: #f9fafb;
  border-radius: 8px;
  margin-bottom: 24px;
}

.detailsGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-top: 16px;
}

.detailItem {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.label {
  font-size: 14px;
  color: #667085;
}

.transactionList {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}

.transactionItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background: #f9fafb;
  border-radius: 8px;
}

.transactionInfo {
  display: flex;
  align-items: center;
  gap: 8px;
}

.transactionDate {
  color: #667085;
  font-size: 14px;
}

@media (max-width: 1024px) {
  .container {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    position: static;
  }
}

@media (max-width: 640px) {
  .modalContent {
    margin: 0;
    max-height: 100vh;
    border-radius: 0;
  }

  .detailsGrid {
    grid-template-columns: 1fr;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .recipientContainer {
    padding: 16px;
  }

  .grid {
    grid-template-columns: 1fr;
  }

  .recipientHeader {
    flex-direction: column;
    gap: 16px;
    align-items: stretch;
  }

  .recipientActions {
    flex-direction: column;
  }

  .searchInput {
    width: 100%;
  }

  .recipientCard {
    flex-direction: column;
    align-items: stretch;
    gap: 16px;
  }

  .recipientControls {
    justify-content: space-between;
  }
}

@media (max-width: 480px) {
  .tabsContainer {
    overflow-x: auto;
  }

  .recipientContact {
    flex-direction: column;
    gap: 4px;
  }

  .tab {
    padding: 12px 16px;
  }
}
