.supportedCountriesContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 20px;
  /* background-color: #F8F8F8; */
  /* background-size: cover; */
}

.mainTitle {
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  color: #1a1a1a;
  margin-bottom: 16px;
}

.subtitle {
  text-align: center;
  color: #666666;
  font-size: 16px;
  margin-bottom: 48px;
}

.countriesGrid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 24px;
  justify-content: center;
}

.countryCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.flagCircle {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-bottom: 8px;
  background-size: cover;
  background-position: center;
}

.currencyCode {
  font-size: 14px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 4px;
}

.countryName {
  font-size: 12px;
  color: #666666;
}

/* Flag classes - you'll need to replace with actual flag images */
/* .nigeria-flag { background-image: url('path-to-nigeria-flag.png'); }
  .ghana-flag { background-image: url('path-to-ghana-flag.png'); } */
/* Add similar classes for all other flags */

@media (max-width: 1200px) {
  .countriesGrid {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media (max-width: 1024px) {
  .countriesGrid {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (max-width: 768px) {
  .mainTitle {
    font-size: 32px;
  }

  .countriesGrid {
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
}

@media (max-width: 640px) {
  .countriesGrid {
    grid-template-columns: repeat(3, 1fr);
  }

  .flagCircle {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 480px) {
  .mainTitle {
    font-size: 28px;
  }

  .subtitle {
    font-size: 14px;
  }

  .countriesGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
}
