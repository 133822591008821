.deliveryOptions {
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 8px;
  background-color: #f9fafb;
}

.deliveryOptions h3 {
  margin: 0 0 1rem 0;
  font-size: 1rem;
  color: #333;
}

.deliveryOption {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
}

.deliveryOption:last-child {
  margin-bottom: 0;
}

.deliveryOption input[type='radio'] {
  margin-right: 0.75rem;
  cursor: pointer;
}

.deliveryOption label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.optionLabel {
  font-size: 0.9rem;
  color: #333;
}

.optionPrice {
  font-size: 0.9rem;
  color: #666;
  font-weight: 500;
}
