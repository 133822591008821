.lipaworld_container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 2rem;
    background-color:#f8f8f8;
  }
  
  .lipaworld_backButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: none;
    border: none;
    font-size: 1rem;
    color: #1F2937;
    cursor: pointer;
    padding: 0;
    margin-bottom: 1.5rem;
  }
  
  .lipaworld_backButton svg {
    fill: currentColor;
  }
  
  .lipaworld_backButton:hover {
    color: #4B5563;
  }
  
  .lipaworld_title {
    font-size: 1.875rem;
    font-weight: 600;
    color: #1F2937;
    margin-bottom: 2rem;
  }
  
  .lipaworld_productGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 1.5rem;
  }
  
  .lipaworld_productCard {
    background: white;
    border-radius: 1rem;
    overflow: hidden;
    transition: transform 0.2s;
  }
  
  .lipaworld_productCard:hover {
    transform: translateY(-2px);
  }
  
  .lipaworld_productImage {
    width: 100%;
    height: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 10px; 
    border-radius: 1rem 1rem 0 0;
    overflow: hidden; 
}

.lipaworld_productImage img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
    border-radius: 1rem; 
}
  
  .lipaworld_productInfo {
    padding: 1rem;
  }
  
  .lipaworld_productTitle {
    font-size: 1rem;
    font-weight: 500;
    color: #1F2937;
    margin-bottom: 0.5rem;
  }
  
  .lipaworld_productPrice {
    color: #6D28D9;
    font-weight: 500;
    font-size: 0.875rem;
  }
  
  .lipaworld_addToCartButton {
    width: 100%;
    padding: 0.75rem;
    background: white;
    border: 1px solid #E5E7EB;
    border-radius: 0 0 1rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .lipaworld_addToCartButton:hover {
    background: #F3F4F6;
  }
  
  .lipaworld_cartIcon {
    fill: #4ADE80;
  }

  .lipaworld_notchContainer {
    position: relative;
    height: 40px; 
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin-top: auto;
  }
  
  .lipaworld_leftNotch {
    position: absolute;
    left: -10px;
    width: 20px;
    height: 20px;
    background-color: #f8f8f8;
    border-radius: 50%;
    z-index: 1;
  }
  
  .lipaworld_rightNotch {
    position: absolute;
    right: -10px;
    width: 20px;
    height: 20px;
    background-color: #f8f8f8; 
    border-radius: 50%;
    z-index: 1;
  }
  
  .lipaworld_dottedLine {
    width: 100%;
    height: 1px;
    border-top: 2px dashed #E5E7EB;
    margin: 0 10px;
  }
  
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .lipaworld_container {
        padding: 1rem;
    }

    .lipaworld_title {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .lipaworld_productGrid {
        grid-template-columns: repeat(2, 1fr); 
        gap: 1rem;
    }

    .lipaworld_backButton {
      margin: 0;
      margin-bottom: 30px;
    }
}

@media (max-width: 480px) {
    .lipaworld_productGrid {
        grid-template-columns: repeat(2, 1fr);
        gap: 0.75rem; 
    }

    .lipaworld_productImage {
        height: 140px;
    }

    .lipaworld_productInfo {
        padding: 0.75rem;
    }

    .lipaworld_productTitle {
        font-size: 0.875rem;
    }

    .lipaworld_productPrice {
        font-size: 0.75rem;
    }
}