.footer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
    background-color: none;
  }
  
  .icon {
    width: 50px;
    height: 50px;
    margin: 0 0.8rem;
    transition: transform 0.2s;
    &:hover {
      transform: scale(1.1);
    }
  }
  
  .foot {
    width: 100vw;
    /* background-color: #ffffff; */
    padding: 20px 0;
    box-sizing: border-box;
    margin: 0;
    position: relative;
  }
  
  .disclosure {
    color: #666;
    font-size: 0.7rem;
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
    padding: 10px;
  
    a {
      color: #000000;
      text-decoration: underline;
    }
  }
  