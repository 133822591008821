.container {
    display: flex;
    min-height: 100vh;
    background-color: #f8f9fa;
    padding: 20px;
    gap: 24px;
}

.h1 {
    color: var(--Text-Dark, #010E1F);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 155.556% */
}

.header {
    color: var(--Text-Dark, #010E1F);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
}


.mainContent {
    flex: 1;
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    gap: 24px;
    margin-top: 24px;
}

.personalInfoCard {
    background: white;
    border-radius: 24px;
    padding: 32px;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
}

.cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
}

.cardHeader h2 {
    font-size: 32px;
    font-weight: 600;
    color: #101828;
    margin: 0;
}

.editButton {
    color: #039855;
    background: none;
    border: none;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    padding: 0;
}

.userProfile {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 48px;
}

.avatar {
    width: 72px;
    height: 72px;
    background: #8D93E8;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
    font-weight: 600;
}

.userInfo h3 {
    font-size: 24px;
    font-weight: 600;
    color: #101828;
    margin: 0 0 8px 0;
}

.tierBadge {
    background: #F8EAFF;
    color: #602182;
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 14px;
    font-weight: 500;
}

.contactDetails {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.fieldGroup label {
    display: block;
    color: #98A2B3;
    font-size: 16px;
    margin-bottom: 8px;
}

.field {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.fieldValue {
    font-size: 16px;
    color: #101828;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;
}

.flag {
    font-size: 20px;
}

.verifiedBadge {
    background: #ECFDF3;
    color: #027A48;
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 14px;
    font-weight: 500;
}

.unverifiedBadge {
    background: #FEF3F2;
    color: #B42318;
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 14px;
    font-weight: 500;
}

.voucherCard {
    background: white;
    border-radius: 24px;
    padding: 32px;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
}

.voucherCard h2 {
    font-size: 32px;
    font-weight: 600;
    color: #101828;
    margin: 0 0 32px 0;
}

.voucherStats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
}

.statCard {
    background: #F9FAFB;
    border-radius: 16px;
    padding: 24px;
    text-align: left;
}

.statCard h3 {
    font-size: 48px;
    font-weight: 600;
    color: #101828;
    margin: 0 0 8px 0;
}

.statLabel {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #039855;
    font-size: 16px;
    font-weight: 500;
}

.arrow {
    font-size: 18px;
}

.recipientCard {
    background: white;
    border-radius: 24px;
    padding: 32px;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
}

.cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
}

.cardHeader h2 {
    font-size: 32px;
    font-weight: 600;
    color: #101828;
    margin: 0;
}

.seeAll {
    color: #039855;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
}

.recipientList {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.recipient {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 4px 0;
}

.avatarrecipient {
    width: 40px;
    height: 40px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.span {
    color: white;
    font-size: 14px;
    font-weight: 500;
}

.span {
    color: white;
    font-size: 14px;
    font-weight: 500;
}

.name {
    font-size: 16px;
    font-weight: 500;
    color: #101828;
}

.recipient:nth-child(2) .avatar span {
    color: white;
}

.contactCard {
    background: white;
    border-radius: 24px;
    padding: 32px;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
}

.contactCard h2 {
    font-size: 32px;
    font-weight: 600;
    color: #101828;
    margin: 0 0 32px 0;
}

.contactList {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.contactItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    color: inherit;
    padding: 4px 0;
}

.contactInfo h3 {
    font-size: 20px;
    font-weight: 600;
    color: #101828;
    margin: 0 0 8px 0;
}

.contactInfo p {
    font-size: 16px;
    color: #98A2B3;
    margin: 0;
    line-height: 1.5;
}

.arrow {
    font-size: 24px;
    color: #039855;
    margin-left: 16px;
}

.paymentCard {
    background: white;
    border-radius: 24px;
    padding: 32px;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
}

.paymentCard h2 {
    font-size: 32px;
    font-weight: 600;
    color: #101828;
    margin: 0 0 32px 0;
}

.methodsList {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.methodItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    color: inherit;
    padding: 4px 0;
}

.methodInfo {
    display: flex;
    align-items: center;
    gap: 16px;
}

.iconWrapper {
    width: 48px;
    height: 48px;
    background: #FFFFFF;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
}

.methodContent h3 {
    font-size: 20px;
    font-weight: 600;
    color: #101828;
    margin: 0 0 4px 0;
}

.methodContent p {
    font-size: 16px;
    color: #98A2B3;
    margin: 0;
}

.arrow {
    font-size: 24px;
    color: #98A2B3;
    margin-left: 16px;
}

.securityCard {
    background: white;
    border-radius: 24px;
    padding: 32px;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
}

.securityCard h2 {
    font-size: 32px;
    font-weight: 600;
    color: #101828;
    margin: 0 0 32px 0;
}

.settingsList {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.settingsItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    color: inherit;
    transition: background-color 0.2s;
    padding: 4px 0;
}

.settingsContent h3 {
    font-size: 20px;
    font-weight: 600;
    color: #101828;
    margin: 0 0 8px 0;
}

.settingsContent p {
    font-size: 16px;
    color: #98A2B3;
    margin: 0;
}

.arrow {
    font-size: 24px;
    color: #98A2B3;
    margin-left: 16px;
}

/* Responsive styles */
@media (max-width: 1024px) {
    .container {
        flex-direction: column;
    }

    .sidebar {
        width: 100%;
        position: static;
    }
}

@media (max-width: 768px) {
    .grid {
        grid-template-columns: 1fr;
    }

    .voucherStats {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 480px) {
    .container {
        padding: 12px;
    }

    .card {
        padding: 16px;
    }
}

/* personal info */
@media (max-width: 640px) {
    .personalInfoCard {
        padding: 24px;
    }

    .cardHeader h2 {
        font-size: 24px;
    }

    .avatar {
        width: 56px;
        height: 56px;
        font-size: 20px;
    }

    .userInfo h3 {
        font-size: 20px;
    }
}

/* security card */
@media (max-width: 640px) {
    .securityCard {
        padding: 24px;
    }

    .securityCard h2 {
        font-size: 24px;
    }

    .settingsContent h3 {
        font-size: 18px;
    }

    .settingsContent p {
        font-size: 14px;
    }
}

/* voucher */
@media (max-width: 768px) {
    .voucherCard {
        padding: 24px;
    }

    .voucherCard h2 {
        font-size: 24px;
        margin-bottom: 24px;
    }

    .voucherStats {
        gap: 16px;
    }

    .statCard {
        padding: 16px;
    }

    .statCard h3 {
        font-size: 32px;
    }

    .statLabel {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .voucherStats {
        grid-template-columns: 1fr;
    }
}

/* payment */
@media (max-width: 640px) {
    .paymentCard {
        padding: 24px;
    }

    .paymentCard h2 {
        font-size: 24px;
        margin-bottom: 24px;
    }

    .iconWrapper {
        width: 40px;
        height: 40px;
    }

    .methodContent h3 {
        font-size: 18px;
    }

    .methodContent p {
        font-size: 14px;
    }
}

/* Recipient */
@media (max-width: 640px) {
    .recipientCard {
        padding: 24px;
    }

    .cardHeader h2 {
        font-size: 24px;
    }

    .seeAll {
        font-size: 14px;
    }

    .name {
        font-size: 14px;
    }

    .avatar {
        width: 32px;
        height: 32px;
    }

    .avatar span {
        font-size: 12px;
    }
}

/* contact */
@media (max-width: 640px) {
    .contactCard {
        padding: 24px;
    }

    .contactCard h2 {
        font-size: 24px;
        margin-bottom: 24px;
    }

    .contactInfo h3 {
        font-size: 18px;
    }

    .contactInfo p {
        font-size: 14px;
    }

    .contactList {
        gap: 16px;
    }
}