.voucherContainer {
  /* padding: 32px; */
  padding-top: 32px;
  max-width: 1200px;
  margin: 0 auto;
}

.voucherTitle {
  font-size: 32px;
  font-weight: 600;
  color: #101828;
  margin-bottom: 24px;
}

.voucherTabs {
  display: flex;
  border-bottom: 1px solid #eaecf0;
  margin-bottom: 24px;
}

.voucherTab {
  padding: 12px 24px;
  border: none;
  background: none;
  color: #667085;
  font-size: 16px;
  cursor: pointer;
  position: relative;
}

.activeVoucherTab {
  color: #039855;
  font-weight: 500;
}

.activeVoucherTab::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 2px;
  background: #039855;
}

.voucherHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.voucherCount {
  font-size: 18px;
  font-weight: 500;
  color: #101828;
}

.voucherCountBadge {
  color: #667085;
  font-weight: normal;
}

.searchBox {
  position: relative;
}

.voucherSearch {
  padding: 10px 16px;
  padding-left: 40px;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  width: 280px;
  font-size: 14px;
}

.voucherSearchIcon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #667085;
}

.voucherCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #eaecf0;
}

.voucherInfo {
  display: flex;
  align-items: center;
  gap: 16px;
}

.voucherLogo {
  width: 64px;
  height: 48px;
  /* background: #FDB022; */
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #101828;
  font-weight: 700;
}

.voucherDetails {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.voucherTypeRow {
  display: flex;
  align-items: center;
  gap: 8px;
}

.voucherType {
  font-size: 16px;
  font-weight: 500;
  color: #101828;
  margin: 0;
}

.voucherStatus {
  padding: 2px 8px;
  border-radius: 16px;
  font-size: 12px;
}

.active {
  background: #ecfdf3;
  color: #027a48;
}

.used {
  background: #fef3f2;
  color: #b42318;
}

.voucherRecipient {
  display: flex;
  gap: 16px;
  color: #667085;
  font-size: 14px;
}

.voucherDate {
  color: #667085;
}

.voucherActions {
  display: flex;
  align-items: center;
  gap: 12px;
}

.redeemButton {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: white;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  color: #344054;
  font-size: 14px;
  cursor: pointer;
}

.voucherMoreButton {
  padding: 0.5rem 1rem;
  background: white;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
}

.voucherDropdownContainer {
  position: relative;
}

.voucherDropdownMenu {
  position: absolute;
  right: 0;
  top: 100%;
  background: white;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  min-width: 200px;
  z-index: 10;
}

.voucherDropdownItem {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 10px 16px;
  border: none;
  background: none;
  text-align: left;
  color: #344054;
  font-size: 14px;
  cursor: pointer;
}

.voucherDropdownItem:hover {
  background: #f9fafb;
}

.container {
  display: flex;
  min-height: 100vh;
  background-color: #f8f9fa;
  padding: 20px;
  gap: 24px;
}

.h1 {
  color: var(--Text-Dark, #010e1f);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 155.556% */
}

.mainContent {
  flex: 1;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto; /* Enable vertical scrolling on overlay */
  padding: 20px;
}

.modalContent {
  background: white;
  border-radius: 12px;
  width: 100%;
  max-width: 600px;
  max-height: 90vh; /* Limit height to 90% of viewport height */
  margin: auto;
  position: relative;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.modalHeader {
  padding: 24px;
  border-bottom: 1px solid #eaecf0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: sticky;
  top: 0;
  background: white;
  border-radius: 12px 12px 0 0;
  z-index: 1;
}

.modalTitle {
  font-size: 24px;
  font-weight: 600;
  color: #101828;
  margin: 0;
  margin-bottom: 4px;
}

.modalSubtitle {
  font-size: 14px;
  color: #667085;
  margin: 0;
}

.modalClose {
  background: none;
  border: none;
  font-size: 24px;
  color: #667085;
  cursor: pointer;
  padding: 4px;
  line-height: 1;
}

.modalBody {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: auto; /* Enable scrolling for modal content */
  flex: 1;
}

.sectionTitle {
  font-size: 14px;
  font-weight: 600;
  color: #101828;
  margin: 0;
  margin-bottom: 8px;
}

.voucherStatusSection .voucherStatus {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 16px;
  font-size: 12px;
}

.voucherCode {
  font-size: 16px;
  color: #667085;
  margin: 0;
}

.redemptionDetails {
  font-size: 14px;
  color: #667085;
  line-height: 1.5;
  margin: 0;
}

.modalActions {
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
}

.actionButton {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background: white;
  cursor: pointer;
}

.moreButton {
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background: white;
  cursor: pointer;
}

.statusGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-top: 12px;
}

.balanceSection {
  margin-top: 24px;
}

.balanceBar {
  height: 8px;
  background: #e0e0e0;
  border-radius: 4px;
  margin: 8px 0;
}

.balanceProgress {
  height: 100%;
  background: #4caf50;
  border-radius: 4px;
}

.balanceValues {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #666;
}

.detailsGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-top: 12px;
}

.detailItem {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.label {
  color: #666;
  font-size: 14px;
}

.copyable {
  display: flex;
  align-items: center;
  gap: 8px;
}

.copyIcon {
  cursor: pointer;
}

.nameTag {
  display: flex;
  align-items: center;
  gap: 4px;
}

.usageList {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
}

.usageItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background: #f5f5f5;
  border-radius: 8px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 20px;
  padding: 20px 0;
}

.pageButton {
  padding: 8px 12px;
  border: 1px solid #e0e0e0;
  background: white;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.pageButton:hover {
  background: #f5f5f5;
}

.activePage {
  background: #039855;
  color: white;
  border-color: #039855;
}

.activePage:hover {
  background: #039855;
}

@media (max-width: 768px) {
  .detailsGrid {
    grid-template-columns: 1fr;
  }

  .usageItem {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
}

/* Responsive styles for modal */
@media (max-width: 480px) {
  .modalOverlay {
    padding: 0;
  }

  .modalContent {
    margin: 0;
    height: 100vh;
    max-height: 100vh;
    border-radius: 0;
  }

  .modalHeader {
    border-radius: 0;
  }

  .modalBody {
    padding: 16px;
  }

  .modalTitle {
    font-size: 20px;
  }
}

@media (max-width: 1024px) {
  .container {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    position: static;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .voucherContainer {
    padding: 16px;
  }

  .voucherHeader {
    flex-direction: column;
    gap: 16px;
    align-items: stretch;
  }

  .voucherSearch {
    width: 100%;
  }

  .voucherCard {
    flex-direction: column;
    align-items: stretch;
    gap: 16px;
  }

  .voucherActions {
    justify-content: space-between;
  }

  .grid {
    grid-template-columns: 1fr;
  }

  .modalOverlay {
    align-items: flex-start;
    padding: 10px;
  }

  .modalContent {
    margin: 10px auto;
    max-height: 95vh;
  }

  .modalHeader {
    padding: 16px;
  }

  .modalBody {
    padding: 16px;
  }
}

@media (max-width: 480px) {
  .voucherTabs {
    overflow-x: auto;
  }

  .voucherTab {
    padding: 12px 16px;
  }

  .voucherRecipient {
    flex-direction: column;
    gap: 4px;
  }

  .voucherTypeRow {
    flex-wrap: wrap;
  }
}
