.container {
    /* background-color: #0A1529; */
    padding: 4rem 2rem;
    color: white;
    min-height: 100vh;
    border-radius: 42px;
    background: var(--Dark-Blue-dark-blue-500, #011A38);
}

.header {
    text-align: center;
    margin-bottom: 4rem;
}

.header h2 {
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.header p {
    color: #8891A7;
    font-size: 1.1rem;
}

.content {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    gap: 3rem;
}

.leftMenu {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 24px;
    background: var(--Colour-Dark-Dark-700, #011226);
}

.menuItem {
    /* background-color: rgba(255, 255, 255, 0.05); */
    border-radius: 1rem;
    padding: 1.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    gap: 1rem;
    align-items: flex-start;
}

.menuItem:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.menuItem.active {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.menuItem h3 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
}

.menuItem p {
    color: #8891A7;
    font-size: 0.9rem;
    line-height: 1.5;
}

.topupIcon,
.sendIcon,
.cashoutIcon {
    width: 2rem;
    height: 2rem;
    background-color: #1E2B45;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
}

.rightContent {
    width: 60%;
    background-color: #011226;
    border-radius: 24px;
    padding: 40px 0 0 40px;
    display: flex;
    justify-content: space-between;
}

.rightContent h3 {
    font-size: 2rem;
    margin: 1rem 0;
}

.rightContent p {
    color: #8891A7;
    margin-bottom: 2rem;
    font-size: 1.1rem;
    line-height: 1.6;
}

.phoneContainer {
    position: relative;
    width: 100%;
    max-width: 375px;
    margin: 0 auto;
}

.phone {
    background: white;
    border-radius: 2rem;
    padding: 1rem;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    aspect-ratio: 9/19;
    width: 100%;
}

.phoneContent {
    background: white;
    height: 100%;
    border-radius: 1.5rem;
    padding: 1rem;
    color: #333;
}

.depositScreen,
.sendScreen,
.cashoutScreen {
    padding: 1rem;
}

.depositScreen h4 {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 2rem;
    color: #333;
}

.amountButtons {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.5rem;
}

.amountButtons button {
    background: #f5f5f5;
    border: none;
    padding: 0.5rem;
    border-radius: 0.5rem;
    font-size: 0.9rem;
    cursor: pointer;
}

.sendScreen input,
.sendScreen textarea,
.cashoutScreen input {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid #eee;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
}

.continueButton {
    width: 100%;
    background: #4CAF50;
    color: white;
    padding: 1rem;
    border: none;
    border-radius: 0.5rem;
    font-size: 1rem;
    cursor: pointer;
    margin-top: auto;
}

.formField {
    margin-bottom: 1rem;
}

.formField label {
    display: block;
    margin-bottom: 0.5rem;
    color: #666;
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .container {
        padding: 3rem 1.5rem;
    }

    .content {
        max-width: 100%;
        gap: 2rem;
    }

    .rightContent {
        width: 55%;
    }
}

@media (max-width: 1024px) {
    .header h2 {
        font-size: 2.5rem;
    }

    .rightContent {
        padding: 30px 0 0 30px;
    }

    .rightContent h3 {
        font-size: 1.8rem;
    }
}

@media (max-width: 768px) {
    .container {
        padding: 2rem 1rem;
        border-radius: 24px;
        margin-top: 40px;
    }

    .content {
        flex-direction: column;
    }

    .rightContent {
        width: 100%;
        padding: 24px;
        flex-direction: column;
    }

    .mobile-imagehow {
        max-width: 100%;
        margin-top: 2rem;
    }

    .text-contenthow {
        width: 100%;
    }

    .menuItem {
        padding: 1.25rem;
    }

    .header {
        margin-bottom: 3rem;
    }
}

@media (max-width: 480px) {
    .container {
        padding: 1.5rem 1rem;
    }

    .header h2 {
        font-size: 2rem;
    }

    .header p {
        font-size: 1rem;
    }

    .menuItem {
        padding: 1rem;
        gap: 0.75rem;
    }

    .menuItem h3 {
        font-size: 1.1rem;
    }

    .menuItem p {
        font-size: 0.85rem;
    }

    .rightContent h3 {
        font-size: 1.5rem;
    }

    .rightContent p {
        font-size: 1rem;
        margin-bottom: 1.5rem;
    }

    .iconContainer {
        width: 1.75rem;
        height: 1.75rem;
    }

    /* .globe-iconhow {
        width: 1rem;
        height: 1rem;
    } */
}