.heroWalletContainer {
  display: flex;
  gap: 1.5rem;
  padding: 2rem 0;
  max-width: 1440px;
  margin: 0 auto;
}

.banner {
  flex: 2; /* Changed from 1 to 2 to make it wider */
  background: linear-gradient(90deg, #2d0f3c 0%, #7a29a2 100%);
  border-radius: 24px;
  overflow: hidden;
  position: relative;
  min-height: 280px;
  display: flex;
  padding: 48px;
  background-image: url("./shop-banner.jpg");
  background-size: cover;
  background-position: center;
  margin-left: 20px;
}

.bannerContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 1;
}

.textContent {
  max-width: 600px;
}

.titleheroshop {
  font-size: 48px;
  font-weight: 600;
  color: white;
  margin-bottom: 16px;
  line-height: 1.2;
}

.highlight {
  color: #bcc1ff;
}

.descriptionheroshop {
  font-size: 16px;
  color: #e2e8f0;
  margin-bottom: 24px;
  line-height: 1.6;
  max-width: 520px;
}

.tryNowButton {
  background: #4ade80;
  color: black;
  border: none;
  padding: 12px 24px;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
  width: fit-content;
}

.tryNowButton:hover {
  background: #22c55e;
}

.bannerImage {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  max-height: 100%;
  object-fit: contain;
  z-index: 0;
}

/* Badge styling */
.badges {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.badge {
  position: absolute;
  background: rgba(255, 255, 255, 0.1);
  /* backdrop-filter: blur(4px); */
  padding: 8px 16px;
  border-radius: 100px;
  color: white;
  font-size: 14px;
  white-space: nowrap;
}

.badge:nth-child(1) {
  top: 24px;
  right: 24px;
}

.badge:nth-child(2) {
  bottom: 24px;
  left: 520px;
}

.walletCard {
  background: white;
  border-radius: 24px;
  padding: 24px;
  min-width: 320px;
  max-width: 380px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.walletCardContainer {
  flex: 1;
  position: relative;
}

.walletHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.walletTitleSection {
  display: flex;
  align-items: center;
  gap: 12px;
}

.walletIcon {
  width: 40px;
  height: 40px;
  background: #f5f5ff;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.walletTitle {
  font-size: 20px;
  font-weight: 600;
  color: #0f172a;
  margin: 0;
}

.topUpButton {
  background: #4ade80;
  color: white;
  border: none;
  border-radius: 12px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.walletBalance {
  padding: 24px 0;
  border-bottom: 1px solid #f0f0f0;
}

.walletSelector {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.currencyLabel {
  color: #64748b;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.balanceAmount {
  font-size: 32px;
  font-weight: 700;
  color: #0f172a;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.currencyCode {
  color: #64748b;
  font-size: 14px;
  margin-left: 4px;
}

.transactionHistory {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #22c55e;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  padding-top: 24px;
}

.transactionHistory img {
  width: 20px;
  height: 20px;
}

.blurredOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(5px);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.comingSoonImage {
  width: 40px;
  height: 40px;
  margin-bottom: 12px;
}

.comingSoonText1 {
  font-size: 24px;
  font-weight: 600;
  color: #010e1f;
}

.comingSoonText {
  font-size: 24px;
  font-weight: 600;
  color: #4ade80;
}

@media (max-width: 1024px) {
  .banner {
    padding: 32px;
  }

  .titleheroshop {
    font-size: 36px;
  }

  .bannerImage {
    max-width: 45%;
  }
}

@media (max-width: 768px) {
  .heroWalletContainer {
    flex-direction: column;
    padding: 1rem;
  }

  .banner {
    width: 100vw;
    margin-left: -1rem;
    margin-right: -1rem;
    border-radius: 0;
    min-height: 220px;
    padding: 24px;
  }

  .textContent {
    max-width: 70%;
  }

  .titleheroshop {
    font-size: 20px;
    margin-bottom: 8px;
  }

  .descriptionheroshop {
    font-size: 13px;
    margin-bottom: 16px;
    line-height: 1.4;
    max-width: 100%;
  }

  .tryNowButton {
    padding: 8px 20px;
    font-size: 14px;
  }

  .bannerImage {
    opacity: 0.9;
    width: 40%;
    right: 16px;
  }

  .badge {
    padding: 6px 12px;
    font-size: 11px;
  }

  .badge:nth-child(1) {
    top: 16px;
    right: 16px;
  }
}

@media (max-width: 480px) {
  .textContent {
    max-width: 75%;
  }

  .titleheroshop {
    font-size: 19px;
  }

  .descriptionheroshop {
    font-size: 0.85rem;
    width: 83%;
  }

  .bannerImage {
    width: 1000vh;
    margin-left: 700px;
  }

  .tryNowButton {
    padding: 8px 20px;
    font-size: 14px;
    margin-left: -8px;
    margin-top: 50px;
  }

  .banner {
    background-position-x: -260px;
  }
}
