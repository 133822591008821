.recipientContainer {
  display: flex;
  gap: 3rem;
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.recipientMainSection {
  flex: 2;
  max-width: 900px;
}

.recipientHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.recipientHeader h1 {
  font-size: 1.5rem;
  font-weight: 600;
}

.createNewButton {
  color: #00ae5a;
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  padding: 0.5rem;
}

.recipientSubtext {
  color: #666;
  margin-bottom: 2rem;
}

.selectSection {
  margin-bottom: 2rem;
}

.selectSection label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.messageSection {
  margin-bottom: 2rem;
}

.messageSection label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.messageInput {
  width: 100%;
  min-height: 120px;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  resize: vertical;
  font-family: inherit;
}

.cartSummary {
  width: 450px;
  padding: 2rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 24px 0px rgba(2, 142, 75, 0.1);
  height: fit-content;
}

.summaryTitle {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  color: #000;
}

.couponSection {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 2rem;
}

.couponInput {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.applyButton {
  padding: 0.6rem 0.9rem;
  color: #00ae5a;
  background: white;
  border: 1px solid #00ae5a;
  border-radius: 7px;
  cursor: pointer;
  font-weight: 900;
}

.summaryDetails {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
  border-top: 1px solid #ddd;
}

.summaryRow {
  display: flex;
  justify-content: space-between;
  color: #666;
}

.totalRow {
  color: #663399;
  font-weight: 600;
  border-top: 2px solid #eee;
  padding-top: 1rem;
}

.checkoutButton {
  width: 100%;
  padding: 1rem;
  background: #00ae5a;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .recipientContainer {
    flex-direction: column;
    padding: 1rem;
  }

  .recipientMainSection {
    max-width: 100%;
  }

  .cartSummary {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .cartContainer {
    flex-direction: column;
    padding: 1rem;
    gap: 2rem;
  }

  .cartMainSection {
    width: 100%;
  }

  .cartHeader h1 {
    font-size: 1.75rem;
  }

  .itemCount {
    font-size: 1.25rem;
  }

  .itemImageContainer {
    width: 100px;
    height: 100px;
  }

  .cartSummary {
    width: 100%;
  }

  .toggleDetailsButton {
    background: none;
    border: none;
    color: #00ae5a;
    cursor: pointer;
    font-size: 1rem;
    margin-left: 8rem;
    display: flex;
    align-items: flex-start;
  }

  .summaryHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .toggleButton {
    font-size: 0.9rem;
  }

  .summaryDetails {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
    border-top: 1px solid #ddd;
  }

  .head {
    display: flex;
  }

  .totalRow {
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .cartContainer {
    padding: 1rem 0.5rem;
  }

  .cartHeader h1 {
    font-size: 1.5rem;
  }

  .itemCount {
    font-size: 1rem;
  }

  .cartItem {
    padding: 1rem 0;
  }

  .itemImageContainer {
    width: 80px;
    height: 80px;
  }

  .itemName {
    font-size: 1.1rem;
    margin-bottom: -20px;
  }

  .quantityButton,
  .quantityInput,
  .deleteButton {
    width: 36px;
    height: 36px;
  }

  .quantityInput {
    width: 40px;
  }

  .quantityButtonimg,
  .trashIcon {
    width: 20px;
    height: 20px;
  }

  .itemDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .itemPrice {
    font-size: 1rem;
    color: #7c3aed;
    margin-top: 0.5rem;
  }

  .rightSection {
    /* display: flex; */
    /* align-items: center; */
    margin-top: 40px;
  }
}

@media (max-width: 360px) {
  .leftSection {
    gap: 0.75rem;
  }

  .itemImageContainer {
    width: 70px;
    height: 70px;
  }

  .itemName {
    font-size: 1rem;
  }
}

.loading {
  padding: 2rem;
  text-align: center;
  background: #f5f5f5;
  border-radius: 8px;
  margin: 1rem 0;
}
