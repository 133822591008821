.container {
    display: flex;
    min-height: 100vh;
    background-color: #f8f9fa;
    padding: 20px;
    gap: 24px;
}

.h1 {
    color: var(--Text-Dark, #010E1F);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 155.556% */
}

.mainContent {
    flex: 1;
}

.contact-section {
    display: flex;
    gap: 40px;
    padding: 40px 60px;
    background-color: #fff;
  }
  
  .contact-form {
    flex: 3;
    padding: 32px;
    background: #fff;
    border-radius: 24px;
    box-shadow: 0px 4px 24px 0px rgba(2, 142, 75, 0.1);
  }
  
  .form-title {
    font-size: 24px;
    font-weight: 600;
    color: #0c0c0c;
  }
  
  .form-subtitle {
    font-size: 14px;
    color: #55667a;
    margin-top: 8px;
  }
  
  .tabsContainer {
    display: flex;
    border-bottom: 1px solid #EAECF0;
    margin-bottom: 24px;
}

.tab {
    padding: 12px 24px;
    border: none;
    background: none;
    color: #667085;
    font-size: 16px;
    cursor: pointer;
    position: relative;
}

.activeTab {
    color: #039855;
    font-weight: 500;
}

.activeTab::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 2px;
    background: #039855;
}
  
  .form {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  .form-groupname {
    display: flex;
  }
  
  .form-group label,
  .form-groupname label {
    font-size: 12px;
    font-weight: 600;
    color: #011a38;
    margin-bottom: 4px;
  }
  
  .form-group input,
  .form-group textarea,
  .form-groupname input,
  .form-groupname textarea {
    padding: 12px 14px;
    border: 1px solid #e2e2e2;
    border-radius: 8px;
  }
  
  textarea {
    resize: none;
    height: 112px;
  }
  
  .submit-button {
    padding: 12px 1rem;
    background-color: #00ae5a;
    color: #fff;
    font-weight: 600;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    border: none;
  }
  
  .contact-info {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 35px;
  }
  
  .contact-info h2 {
    font-size: 24px;
    font-weight: 600;
    color: #0c0c0c;
  }
  
  .contact-info p {
    font-size: 14px;
    font-weight: 500;
    color: #55667a;
  }
  
  .contact-info a {
    color: #008846;
    font-weight: 500;
  }
  
  .social-media {
    display: flex;
    gap: 12px;
    margin-top: 8px;
  }


@media (max-width: 1024px) {
    .container {
        flex-direction: column;
    }

    .sidebar {
        width: 100%;
        position: static;
    }
}