.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: white;
}

.formWrapper {
  width: 100%;
  max-width: 480px;
}

.logoContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.logo {
  height: 32px;
}

.welcomeText {
  text-align: center;
  margin-bottom: 2rem;
}

.welcomeText h1 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.welcomeText p {
  color: #666;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.nameFields {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

@media (min-width: 768px) {
  .nameFields {
    flex-direction: row;
  }
}

.inputGroup {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.inputGroup label {
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.inputGroup input {
  padding: 0.75rem 1rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  outline: none;
  width: 100%;
}

.inputGroup input:focus {
  border-color: black;
}

.passwordInput {
  position: relative;
}

.passwordInput input {
  padding-right: 2.5rem;
}

.passwordToggle {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
}

.passwordToggle:hover {
  color: #333;
}

.passwordHint {
  font-size: 0.875rem;
  color: #666;
  margin-top: 0.25rem;
}

.termsGroup {
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
}

.termsGroup input[type="checkbox"] {
  width: 1rem;
  height: 1rem;
  margin-top: 0.25rem;
}

.termsGroup label {
  font-size: 0.875rem;
}

.termsGroup a {
  color: #22c55e;
  text-decoration: none;
}

.termsGroup a:hover {
  text-decoration: underline;
}

.signupButton {
  padding: 0.75rem;
  padding-left: 7rem;
  padding-right: 7rem;
  background-color: #22c55e;
  color: white;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: background-color 0.2s;
}

.signupButton:hover {
  background-color: #16a34a;
}

.googleButton {
  padding: 0.75rem;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: background-color 0.2s;
}

.googleButton:hover {
  background-color: #f9fafb;
}

.googleIcon {
  width: 20px;
  height: 20px;
}

.signin {
  text-align: center;
  font-size: 0.875rem;
}

.signin a {
  color: #22c55e;
  text-decoration: none;
}

.signin a:hover {
  text-decoration: underline;
}

.countrySelect {
  width: 100%;
  padding: 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 16px;
  color: #1a202c;
  background-color: white;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23666666%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.4-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 12px;
}

.countrySelect:focus {
  outline: none;
  border-color: #4299e1;
  box-shadow: 0 0 0 1px #4299e1;
}
