.actionButtonContainer {
  font-size: 0.7rem;
  color: #333;
  text-rendering: optimizeLegibility;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.actionButtonContainer > div {
  flex: 1;
}
