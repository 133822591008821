.contentBody {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 4rem 0;
  flex-direction: column;
  width: 100%;
}

/* Mobile */
@media (max-width: 700px) {
  .contentBody {
    padding: 1rem 0;
  }
}
