.container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background-color: white;
  }
  .formWrapper {
    width: 100%;
    max-width: 480px;
  }
  .logoContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }
  .logo {
    height: 32px;
  }
  .verifyEmail {
    text-align: center;
    margin-bottom: 2rem;
  }
  .verifyEmail h1 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  .verifyEmail p {
    color: #666;
  }
  .otpInput {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
  .otpDigit {
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    outline: none;
  }
  .otpValid {
    border-color: #22c55e;
    box-shadow: 0 0 0 2px rgba(34, 197, 94, 0.4);
  }
  .otpInvalid {
    border-color: #ef4444;
    box-shadow: 0 0 0 2px rgba(239, 68, 68, 0.4);
  }
  .buttonContainer {
    display: flex;
    gap: 1rem;
  }
  .backButton {
    padding: 0.75rem 1rem;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    cursor: pointer;
    font-weight: 500;
    background-color: white;
    transition: background-color 0.2s;
  }
  .backButton:hover {
    background-color: #f5f5f5;
  }
  .verifyButton {
    flex: 1;
    padding: 0.75rem;
    background-color: #22c55e;
    color: white;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s;
  }
  .verifyButton:hover {
    background-color: #16a34a;
  }
  .resendEmail {
    font-size: 0.875rem;
    color: #666;
    margin-top: 1rem;
  }
  .resendLink {
    color: #22c55e;
    text-decoration: none;
    cursor: pointer;
  }
  .resendLink:hover {
    text-decoration: underline;
  }
  .signIn {
    text-align: center;
    font-size: 0.875rem;
    margin-top: 2rem;
  }
  .signInLink {
    color: #22c55e;
    text-decoration: none;
  }
  .signInLink:hover {
    text-decoration: underline;
  }
  @media (min-width: 768px) {
    .otpDigit {
      width: 3.5rem;
      height: 3.5rem;
      font-size: 1.75rem;
    }
  }