.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 4rem;
}

/* Mobile */
@media (max-width: 700px) {
  .main {
    justify-content: flex-start;
    height: 100vh;
    padding: 0.7rem;
  }
}
