.lipaworld_section {
  padding: 2rem;
  max-width: 1440px;
  margin: 0 auto;
  background-color: #f8f8f8;
}

.lipaworld_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.lipaworld_titleContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.lipaworld_sectionTitle {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1f2937;
}

.lipaworld_seeAllLink {
  color: #6d28d9;
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
}

.lipaworld_seeAllLink:hover {
  text-decoration: underline;
}

.lipaworld_navigation {
  display: flex;
  gap: 0.5rem;
}

.lipaworld_navButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #e5e7eb;
  background: white;
  cursor: pointer;
  transition: all 0.2s;
}

.lipaworld_navButton svg {
  fill: #4b5563;
}

.lipaworld_navButton:hover {
  background: #f3f4f6;
}

.lipaworld_navButtonDisabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.lipaworld_productsContainer {
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 0.5rem;
}

.lipaworld_productsContainerColumn {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  overflow-x: auto;
  justify-content: flex-start;
  padding: 0.5rem;
}

.lipaworld_productsContainer::-webkit-scrollbar {
  display: none;
}

.lipaworld_productCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 auto;
  width: 280px;
  background: white;
  border-radius: 1rem;
  overflow: hidden;
  scroll-snap-align: start;
}

/* .lipaworld_buttonWrapper {
    margin-top: auto;
    width: 100%;
  } */

.lipaworld_productImage {
  width: 100%;
  /* height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 10px 0 10px;
  border-radius: 1rem 1rem 0 0;
  overflow: hidden;
}

.lipaworld_productImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
}

.lipaworld_productInfo {
  padding: 1rem;
}

.lipaworld_productTitle {
  font-size: 1rem;
  font-weight: 500;
  color: #1f2937;
  margin-bottom: 0.5rem;
}

.lipaworld_productPrice {
  color: #6d28d9;
  font-weight: 500;
  font-size: 0.875rem;
}

.lipaworld_addToCartButton {
  width: 100%;
  padding: 0.75rem;
  background: white;
  border-top: 1px solid #e5e7eb;
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-radius: 0 0 1rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.2s;
}

.lipaworld_disabledButton {
  width: 100%;
  padding: 0.75rem;
  background: #efeaea82;
  border-top: 1px solid #e5e7eb;
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-radius: 0 0 1rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.2s;
  color: gray;
}

.lipaworld_addToCartButton:hover {
  background: #f3f4f6;
}

.lipaworld_cartIcon {
  fill: #4ade80;
}

.lipaworld_notchContainer {
  position: relative;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  /* margin-top: auto; */
}

.lipaworld_leftNotch {
  position: absolute;
  left: -10px;
  width: 20px;
  height: 20px;
  background-color: #f8f8f8;
  border-radius: 50%;
  z-index: 1;
}

.lipaworld_rightNotch {
  position: absolute;
  right: -10px;
  width: 20px;
  height: 20px;
  background-color: #f8f8f8;
  border-radius: 50%;
  z-index: 1;
}

.lipaworld_dottedLine {
  width: 100%;
  height: 1px;
  border-top: 2px dashed #f8f8f8;
  margin: 0 10px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .lipaworld_section {
    padding: 1rem;
  }

  .lipaworld_productCard {
    width: 240px;
  }

  .lipaworld_productImage {
    height: 140px;
  }
}

@media (max-width: 480px) {
  .lipaworld_header {
    flex-direction: row;
    align-items: flex-start;
    gap: 1rem;
    padding: 1rem;
    margin-bottom: 0.5rem;
  }

  .lipaworld_productsContainerColumn {
    justify-content: center;
  }

  .lipaworld_section {
    padding: 0;
    /* min-height: 80vh; */
  }

  .lipaworld_navigation {
    align-self: flex-end;
  }

  .lipaworld_productCard {
    width: 200px;
  }

  .lipaworld_productImage {
    height: 120px;
  }
}
