.cartContainer {
  display: flex;
  gap: 2rem;
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
  background: #fff;
}

.cartMainSection {
  flex: 2;
  max-width: 800px;
}

.cartHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eaecf0;
}

.cartHeader h1 {
  font-size: 2rem;
  font-weight: 600;
  color: #111827;
}

.itemCount {
  color: #6b7280;
  font-size: 1.5rem;
  font-weight: 400;
}

.cartItemsList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

.cartItem {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 0;
  border-bottom: 1px solid #eaecf0;
}

.leftSection {
  display: flex;
  gap: 1.25rem;
  align-items: center;
}

.itemImageContainer {
  width: 120px;
  height: 120px;
  background: #ffffff;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e8e8e8;
}

.itemImage {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.itemDetails {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.itemName {
  font-weight: 500;
  font-size: 1.25rem;
  color: #111827;
}

.quantityControl {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.quantityButton {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #d1d5db;
  background: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quantityButtonimg {
  width: 24px;
  height: 24px;
}

.quantityInput {
  width: 48px;
  height: 40px;
  text-align: center;
  border: 1px solid #d1d5db;
  font-size: 1rem;
  background-color: white;
  border-radius: 8px;
}

.rightSection {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.itemPrice {
  color: #7c3aed;
  font-weight: 600;
  font-size: 1.25rem;
}

.deleteButton {
  background: white;
  border: 1px solid #d1d5db;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trashIcon {
  width: 24px;
  height: 24px;
}

/* Cart Summary Styles */
.cartSummary {
  width: 450px;
  padding: 2rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 24px 0px rgba(2, 142, 75, 0.1);
  height: fit-content;
}

.summaryTitle {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  color: #000;
}

.couponSection {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 2rem;
}

.couponInput {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.applyButton {
  padding: 0.6rem 0.9rem;
  color: #00ae5a;
  background: white;
  border: 1px solid #00ae5a;
  border-radius: 7px;
  cursor: pointer;
  font-weight: 900;
}

.summaryDetails {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
  border-top: 1px solid #ddd;
}

.summaryRow {
  display: flex;
  justify-content: space-between;
  color: #666;
}

.totalRow {
  color: #663399;
  font-weight: 600;
  border-top: 2px solid #eee;
  padding-top: 1rem;
}

.checkoutButton {
  width: 100%;
  padding: 1rem;
  background: #00ae5a;
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
}

.verifyButton {
  border: 3px lightgreen solid;
  width: 200px;
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .cartContainer {
    flex-direction: column;
    padding: 1rem;
    gap: 2rem;
  }

  .cartMainSection {
    width: 100%;
  }

  .cartHeader h1 {
    font-size: 1.75rem;
  }

  .itemCount {
    font-size: 1.25rem;
  }

  .itemImageContainer {
    width: 100px;
    height: 100px;
  }

  .cartSummary {
    width: 100%;
  }

  .toggleDetailsButton {
    background: none;
    border: none;
    color: #00ae5a;
    cursor: pointer;
    font-size: 1rem;
    margin-left: 8rem;
    display: flex;
    align-items: flex-start;
  }

  .summaryHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .toggleButton {
    font-size: 0.9rem;
  }

  .summaryDetails {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
    border-top: 1px solid #ddd;
  }

  .head {
    display: flex;
  }

  .totalRow {
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .cartContainer {
    padding: 1rem 0.5rem;
  }

  .cartHeader h1 {
    font-size: 1.5rem;
  }

  .itemCount {
    font-size: 1rem;
  }

  .cartItem {
    padding: 1rem 0;
  }

  .itemImageContainer {
    width: 80px;
    height: 80px;
  }

  .itemName {
    font-size: 1.1rem;
    margin-bottom: -20px;
  }

  .quantityButton,
  .quantityInput,
  .deleteButton {
    width: 36px;
    height: 36px;
  }

  .quantityInput {
    width: 40px;
  }

  .quantityButtonimg,
  .trashIcon {
    width: 20px;
    height: 20px;
  }

  .itemDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .itemPrice {
    font-size: 1rem;
    color: #7c3aed;
    margin-top: 0.5rem;
  }

  .rightSection {
    /* display: flex; */
    /* align-items: center; */
    margin-top: 40px;
  }
}

@media (max-width: 360px) {
  .leftSection {
    gap: 0.75rem;
  }

  .itemImageContainer {
    width: 70px;
    height: 70px;
  }

  .itemName {
    font-size: 1rem;
  }
}
