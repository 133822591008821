.lipaworld_navButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #666;
  font-size: 0.875rem;
  position: relative;
  padding-left: 23px;
}

.lipaworld_navIcon {
  fill: #666;
  width: 20px;
  height: 20px;
}

.lipaworld_quantity {
  height: 20px;
  border-radius: 50%;
  width: 20px;
  background-color: #e0103a;
  position: absolute;
  font-size: 10px;
  right: 25px;
  top: -10px;
  justify-content: center;
  align-items: center;
  color: #fff;
}

@media (max-width: 768px) {
  .lipaworld_navButton {
    display: grid;
  }
}
