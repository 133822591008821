.moneyTransferContainer {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    text-align: center;
  }
  
  .transparencyBadge {
    display: inline-flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 8px 20px;
    border-radius: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 24px;
    color: #602182;
    font-size: 14px;
    font-weight: 600;
  }
  
  .dot {
    margin: 0 8px;
    color: #602182;
  }
  
  .moneyTransferTitle {
    font-size: 48px;
    font-weight: 700;
    color: #1a1a1a;
    line-height: 1.2;
    margin-bottom: 24px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .moneyTransferDescription {
    font-size: 16px;
    line-height: 1.6;
    color: #666666;
    max-width: 900px;
    margin: 0 auto 48px;
  }
  
  /* .phoneImageContainer {
    width: 100%;
    background: linear-gradient(to bottom, #e5e5e5, #333333);
    padding: 60px 20px;
    border-radius: 24px;
  } */
  
  /* .phoneWrapper {
    max-width: 375px;
    margin: 0 auto;
  } */
  
  .phoneImage {
    width: 100%;
    border-radius: 24px;
  }
  
  @media (max-width: 768px) {
    .moneyTransferTitle {
      font-size: 36px;
      padding: 0 20px;
    }
  
    .moneyTransferDescription {
      font-size: 14px;
      padding: 0 20px;
    }
  
    .phoneImageContainer {
      padding: 40px 20px;
      border-radius: 16px;
    }
  }
  
  @media (max-width: 480px) {
    .moneyTransferTitle {
      font-size: 28px;
    }
  
    .transparencyBadge {
      font-size: 12px;
      padding: 6px 16px;
    }
  
    .phoneWrapper {
      max-width: 300px;
    }
  }