.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 1rem;
}

.modalContent {
  background: white;
  border-radius: 24px;
  padding: 2rem;
  width: 100%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.modalHeader h2 {
  font-size: clamp(1.25rem, 4vw, 1.5rem);
  font-weight: 600;
}

.closeButton {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  margin: -0.5rem;
}

.modalSubtext {
  color: #666;
  margin-bottom: 1.5rem;
  font-size: clamp(0.875rem, 3vw, 1rem);
}

.createForm {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.formRow {
  display: flex;
  gap: 1rem;
}

.formGroup {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.formGroup label {
  font-weight: 500;
  font-size: clamp(0.875rem, 3vw, 1rem);
}

.formGroup input {
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: clamp(0.875rem, 3vw, 1rem);
  width: 100%;
}

.phoneInput {
  display: flex;
  gap: 0.5rem;
}

.countryCode {
  width: 110px;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: white;
  font-size: clamp(0.875rem, 3vw, 1rem);
}

.modalActions {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

.cancelButton,
.createButton {
  flex: 1;
  padding: 0.875rem;
  border-radius: 8px;
  cursor: pointer;
  font-size: clamp(0.875rem, 3vw, 1rem);
  min-height: 48px;
}

.cancelButton {
  border: 1px solid #ddd;
  background: white;
}

.createButton {
  border: none;
  background: #00ae5a;
  color: white;
}

@media (max-width: 768px) {
  .modalContent {
    padding: 1.5rem;
    margin: 0;
    border-radius: 16px;
  }

  .formRow {
    flex-direction: column;
    gap: 1.25rem;
  }
}

@media (max-width: 480px) {
  .modalOverlay {
    padding: 0.5rem;
  }

  .modalContent {
    padding: 1.25rem;
    border-radius: 12px;
  }

  .modalActions {
    flex-direction: column;
  }

  .phoneInput {
    flex-direction: column;
  }

  .countryCode {
    width: 100%;
  }
}

/* Custom scrollbar for the modal */
.modalContent::-webkit-scrollbar {
  width: 8px;
}

.modalContent::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.modalContent::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.modalContent::-webkit-scrollbar-thumb:hover {
  background: #666;
}

.errorMessage {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

.returnButton {
  width: 100%;
  padding: 12px 24px;
  background-color: #f3f4f6;
  color: #374151;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.returnButton:hover {
  background-color: #e5e7eb;
}
