.nonLipaContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem;
  gap: 4rem;
  max-width: 1200px;
  margin: 0 auto;
}

.contentSection {
  flex: 1;
  max-width: 500px;
}

.nonLipaTitle {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 1.5rem;
}

.nonLipaDescription {
  color: #666;
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.nonLipaList {
  list-style: none;
  padding: 0;
  margin-bottom: 2rem;
}

.nonLipaList li {
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 0.75rem;
  color: #666;
}

.nonLipaList li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  background-color: #6b46c1;
  border-radius: 50%;
}

.nonLipaButton {
  padding: 12px 24px;
  background-color: transparent;
  border: 1px solid #22c55e;
  border-radius: 6px;
  color: #22c55e;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.nonLipaButton:hover {
  background-color: #22c55e;
  color: white;
}

.phoneContainer {
  flex: 1;
  max-width: 400px;
  padding: 2rem;
  background-color: #f8f9fe;
  border-radius: 2rem;
}

.phoneFrame {
  background-color: white;
  border-radius: 1.5rem;
  overflow: hidden;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  width: 140%;
}

.phoneHeader {
  background-color: #1a1a1a;
  padding: 1rem;
}

.headerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.marketplace {
  font-size: 0.9rem;
}

.walletSection {
  display: flex;
  align-items: center;
  background-color: #4CAF50;
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
}

.wallet {
  margin-right: 0.5rem;
  font-size: 0.9rem;
}

.walletBadge {
  background-color: #388E3C;
  padding: 0.1rem 0.4rem;
  border-radius: 1rem;
  font-size: 0.8rem;
}

.navigationBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #f0f0f0;
}

.backArrow, .plusIcon {
  font-size: 1.5rem;
  color: #333;
}

.recipientText {
  font-weight: 600;
}

.generateSection {
  padding: 2rem 1rem;
}

.generateTitle {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  text-align: center;
}

.generateDescription {
  text-align: center;
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 2rem;
}

.formGroup {
  margin-bottom: 1.5rem;
}

.inputLabel {
  display: block;
  font-size: 0.9rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.recipientInput,
.messageInput {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  font-size: 0.9rem;
}

@media (max-width: 968px) {
  .nonLipaContainer {
    flex-direction: column;
    padding: 2rem;
    gap: 2rem;
  }

  .contentSection {
    text-align: center;
    max-width: 100%;
  }

  .phoneContainer {
    width: 100%;
    max-width: 100%;
    padding: 1rem;
  }

  .phoneFrame {
    max-width: 400px;
    margin: 0 auto;
  }
}

@media (max-width: 480px) {
  .nonLipaTitle {
    font-size: 2rem;
  }

  .nonLipaContainer {
    padding: 1rem;
  }

  .phoneContainer {
    padding: 0.5rem;
  }
}