.searchBarContainer {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    background-color: #F5F5F5;
    border-radius: 12px;
    margin: 20px 20px 24px 20px;
  }
  
  .closeIcon {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    cursor: pointer;
  }
  
  .searchInput {
    flex-grow: 1;
    border: none;
    outline: none;
    font-size: 16px;
    background: transparent;
    color: #8E8E93;
  }
  
  .searchIcon {
    width: 20px;
    height: 20px;
    opacity: 1;
    margin-left: 8px;
  }
  
  .suggestionsContainer {
    padding: 0 20px;
    margin-bottom: 32px;
  }
  
  .suggestionGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
  }
  
  .suggestionItem {
    padding: 12px;
    border-radius: 12px;
    background-color: #F5F5F5;
    text-align: center;
    font-size: 14px;
    color: #000;
  }
  
  .recentSearchesContainer {
    padding: 0 20px;
  }
  
  .title {
    font-size: 16px;
    color: #666;
    margin-bottom: 16px;
    font-weight: normal;
  }
  
  .list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .listItem {
    font-size: 17px;
    padding: 12px 0;
    color: #000;
    border-bottom: 1px solid #F5F5F5;
  }