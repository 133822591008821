.receiveMoneyContainer {
    max-width: 1200px;
    margin: 0 auto;
    padding: 80px 20px;
  }
  
  .contentWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 60px;
    align-items: center;
  }
  
  .textSection {
    max-width: 560px;
  }
  
  .title {
    font-size: 48px;
    font-weight: 700;
    line-height: 1.2;
    color: #1a1a1a;
    margin-bottom: 24px;
  }
  
  .description {
    font-size: 16px;
    line-height: 1.6;
    color: #666666;
    margin-bottom: 32px;
  }
  
  .waitlistButton {
    padding: 12px 24px;
    background-color: transparent;
    border: 1px solid #22c55e;
    border-radius: 6px;
    color: #22c55e;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .waitlistButton:hover {
    background-color: #22c55e;
    color: white;
  }
  
  .imageSection {
    position: relative;
  }
  
  .imageContainer {
    position: relative;
    border-radius: 24px;
    overflow: hidden;
    background-color: #f5f5f5;
  }
  
  .userImage {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 24px;
  }
  
  .transactionCard {
    position: absolute;
    top: 40px;
    right: 40px;
    background: white;
    padding: 16px;
    border-radius: 12px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    text-align: center;
  }
  
  .checkIcon {
    width: 32px;
    height: 32px;
    background-color: #22c55e;
    border-radius: 50%;
    margin: 0 auto 8px;
  }
  
  .transactionText {
    color: #22c55e;
    font-size: 14px;
    margin-bottom: 4px;
  }
  
  .amount {
    color: #1a1a1a;
    font-size: 16px;
    font-weight: 600;
  }
  
  @media (max-width: 1024px) {
    .title {
      font-size: 40px;
    }
  }
  
  @media (max-width: 768px) {
    .contentWrapper {
      grid-template-columns: 1fr;
      gap: 40px;
    }
  
    .textSection {
      max-width: 100%;
    }
  
    .title {
      font-size: 36px;
    }
  
    .transactionCard {
      top: 20px;
      right: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .receiveMoneyContainer {
      padding: 40px 20px;
    }
  
    .title {
      font-size: 32px;
    }
  
    .description {
      font-size: 14px;
    }
  
    .transactionCard {
      padding: 12px;
    }
  
    .checkIcon {
      width: 24px;
      height: 24px;
    }
  
    .transactionText {
      font-size: 12px;
    }
  
    .amount {
      font-size: 14px;
    }
  }