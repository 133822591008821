.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem;
  max-width: 480px;
  margin: 0 auto;
  min-height: 100vh;
  background-color: #fff;
}

.title {
  font-size: 24px;
  font-weight: 600;
  color: #111827;
  margin-bottom: 8px;
  text-align: center;
}

.subtitle {
  font-size: 16px;
  color: #6B7280;
  text-align: center;
  margin-bottom: 32px;
}

.logoContainer {
  margin-bottom: 48px;
}

.logoImage {
  height: 32px;
  object-fit: contain;
}

.emailContainer {
  display: flex;
  gap: 8px;
  align-items: center;
  background-color: #F9FAFB;
  padding: 12px 16px;
  border-radius: 12px;
  margin-bottom: 32px;
  width: 100%;
  justify-content: space-between;
}

.loggedInText {
  font-size: 14px;
  color: #6B7280;
}

.email {
  font-size: 14px;
  color: #111827;
  font-weight: 500;
}

.inputWrapper {
  width: 100%;
  margin-bottom: 24px;
}

.label {
  font-size: 14px;
  color: #111827;
  font-weight: 500;
  margin-bottom: 8px;
  display: block;
}

.inputContainer {
  display: flex;
  align-items: center;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  width: 100%;
  padding: 12px 16px;
  background-color: #fff;
}

.flag {
  width: 24px;
  height: 16px;
  margin-right: 8px;
}

.dropdown {
  border: none;
  outline: none;
  margin-right: 8px;
  font-size: 14px;
  color: #111827;
  background: transparent;
  padding-right: 16px;
  cursor: pointer;
}

.phoneNumberInput {
  flex-grow: 1;
  border: none;
  outline: none;
  font-size: 14px;
  color: #111827;
  background: transparent;
}

.phoneNumberInput::placeholder {
  color: #9CA3AF;
}

.consentContainer {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 24px;
  width: 100%;
}

.checkboxWrapper {
  margin-top: 2px;
}

input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #E5E7EB;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  position: relative;
  background-color: #fff;
}

input[type="checkbox"]:checked {
  background-color: #4CAF50;
  border-color: #4CAF50;
}

input[type="checkbox"]:checked::before {
  content: '';
  position: absolute;
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.consentText {
  font-size: 14px;
  color: #6B7280;
  line-height: 1.4;
}

.button {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.2s;
}

.button:disabled {
  background-color: #E5E7EB;
  color: #9CA3AF;
  cursor: not-allowed;
}

.buttonActive {
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
}

.buttonActive:hover {
  background-color: #43A047;
}

@media (max-width: 640px) {
  .container {
    padding: 1rem;
  }

  .emailContainer {
    padding: 10px 14px;
  }

  .inputContainer {
    padding: 10px 14px;
  }
}