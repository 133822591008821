.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: #ffffff;
}

.logoContainer {
  margin-bottom: 2rem;
}

.logo {
  height: 40px;
}

.loggedInInfo {
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  padding: 0.75rem 1rem;
  border-radius: 16px;
  margin-bottom: 2rem;
  justify-content: space-between;
}

.loggedInText {
  color: #55667a;
  font-weight: 600;
  font-size: 12px;
  margin-right: 0.5rem;
}

.email {
  color: #0c0c0c;
  font-weight: 600;
  font-size: 14px;
}

.content {
  text-align: center;
}

.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: #0c0c0c;
  margin: 1rem 0;
}

.description {
  color: #55667a;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 1.5rem;
}

.buttonGroup {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.addButton {
  background-color: #00ae5a;
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  transition: background-color 0.2s;
}

.addButton:hover {
  background-color: #008f47;
}

.continueButton {
  background-color: #ffffff;
  color: #010e1f;
  padding: 0.75rem 1.5rem;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  transition: background-color 0.2s;
}

.continueButton:hover {
  background-color: #f0f0f0;
}

.note {
  color: #602182;
  font-size: 14px;
  line-height: 20px;
}

@media (max-width: 768px) {
  .buttonGroup {
    flex-direction: column;
    width: 100%;
  }

  .addButton,
  .continueButton {
    width: 100%;
    padding: 0.75rem;
  }
}

