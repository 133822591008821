.emptyOrdersContainer {
    width: 100%;
    /* min-height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.emptyOrdersCard {
    border-radius: 12px;
    padding: 40px;
    text-align: center;
    max-width: 500px;
    width: 100%;
    margin-top: 0;
}

.cartIconWrapper {
    position: relative;
    display: inline-block;
    margin-bottom: 24px;
}

.cartIcon {
    background-color: #f5f3ff;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
}

.zeroIndicator {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #f5f3ff;
    color: #6b46c1;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.emptyOrdersTitle {
    color: #1a1a1a;
    font-size: 24px;
    margin-bottom: 16px;
    font-weight: 600;
}

.emptyOrdersDescription {
    color: #666666;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 24px;
    padding: 0 20px;
}

.startShoppingButton {
    background: #00AE5A;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 12px 32px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.startShoppingButton:hover {
    background-color: #45a049;
}

.lipaworld_backButton svg {
    fill: currentColor;
  }

  .lipaworld_backButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: none;
    border: none;
    font-size: 1rem;
    color: #1F2937;
    cursor: pointer;
    padding: 0;
    margin-top: 40px;
    margin-left: 20px;
    /* margin-bottom: 1.5rem; Ensures spacing below the button */
}

.lipaworld_backButton:hover {
    color: #4B5563;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
    .emptyOrdersCard {
        padding: 30px 20px;
    }

    .emptyOrdersTitle {
        font-size: 20px;
    }

    .emptyOrdersDescription {
        font-size: 14px;
        padding: 0 10px;
    }

    .cartIcon {
        width: 60px;
        height: 60px;
        font-size: 24px;
    }

    .lipaworld_backButton {
        /* margin: 0;
        margin-bottom: 30px; */
        margin-top: 40px;
        margin-left: 20px;
      }
}

@media screen and (max-width: 480px) {
    .emptyOrdersCard {
        padding: 20px 15px;
    }

    .startShoppingButton {
        padding: 10px 24px;
        font-size: 14px;
    }
}