.voucherContainer {
  margin-bottom: 50px;
  box-shadow: 0 4px 8px rgba(15, 188, 73, 0.2);
  background-color: white;
  padding: 2rem;
  width: 100%;
}

.actionButtonLink {
  display: inline-block;
  padding-bottom: 0.1rem;
  font-weight: 400;
  background-color: transparent;
  border: none;
  font-size: 0.7rem;
  border-radius: 0;
  color: #333;
  border-bottom: 1px solid #0fbc49;
  cursor: pointer;
  transition: color 0.2s, box-shadow 0.2s;
}

.actionButtonLink:hover {
  color: #0fbc49;
  box-shadow: 0 4px 8px rgba(15, 188, 73, 0.2);
}

.actionButtonLink:focus {
  outline: 2px solid #0fbc49;
  outline-offset: 2px;
}

.h1 {
  color: var(--Text-Dark, #010e1f);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 155.556% */
  padding: 2rem;
  padding-bottom: 0;
}

.purchaseDetails {
  font-size: 0.8rem;
  color: #333;
}
