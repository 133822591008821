.virtualCardContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px 20px;
}

.contentWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  align-items: center;
}

.cardSection {
  background: var(--Accent-Purple-accent-purple-50, #F5F6FF);
  padding: 40px;
  border-radius: 24px;
}

.cardWrapper {
  width: 500px;
  height: 386px;
  margin: 0 auto;
}

.green {
  color: var(--Green-green-600, #00AE5A);
}

.center {
  align-items: center;
  text-align: center;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
}

.card {
  background: linear-gradient(45deg, #0f172a, #1e293b);
  border-radius: 16px;
  padding: 24px;
  color: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.lipaLogo {
  font-size: 20px;
  font-weight: 600;
}

.visaLogo {
  font-size: 24px;
  font-weight: 700;
}

.cardNumber {
  font-size: 24px;
  letter-spacing: 2px;
  margin-bottom: 40px;
}

.cardFooter {
  display: flex;
  justify-content: space-between;
}

.label {
  font-size: 12px;
  opacity: 0.8;
  margin-bottom: 4px;
}

.value {
  font-size: 16px;
}

.contentSection {
  max-width: 480px;
}

.title {
  font-size: 40px;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 16px;
}

.subtitle {
  font-size: 18px;
  color: #666666;
  margin-bottom: 16px;
}

.description {
  font-size: 16px;
  color: #666666;
  margin-bottom: 24px;
}

.featuresList {
  list-style: none;
  padding: 0;
  margin-bottom: 24px;
}

.featureItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  position: relative;
  padding-left: 24px;
}

.featureItem::before {
  content: '';
  position: absolute;
  left: 0;
  top: 6px;
  width: 8px;
  height: 8px;
  background-color: #6b46c1;
  border-radius: 50%;
}

.featureTitle {
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 4px;
}

.featureText {
  color: #666666;
  font-size: 14px;
}

.stayTuned {
  font-size: 16px;
  color: #1a1a1a;
  margin-bottom: 24px;
}

.waitlistButton {
  padding: 12px 24px;
  background-color: transparent;
  border: 1px solid #22c55e;
  border-radius: 6px;
  color: #22c55e;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.waitlistButton:hover {
  background-color: #22c55e;
  color: white;
}

@media (max-width: 1024px) {
  .title {
    font-size: 36px;
  }
}

@media (max-width: 768px) {
  .contentWrapper {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .cardSection {
    padding: 24px;
  }

  .contentSection {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .virtualCardContainer {
    padding: 40px 20px;
  }

  .title {
    font-size: 32px;
  }

  .cardSection {
    padding: 16px;
  }

  .card {
    padding: 16px;
  }

  .cardNumber {
    font-size: 20px;
  }

  .value {
    font-size: 14px;
  }
}