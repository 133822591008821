.walletFeaturesContainer {
    max-width: 1200px;
    margin: 0 auto;
    padding: 60px 20px;
  }
  
  .walletFeaturesTitle {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    color: #1a1a1a;
    margin-bottom: 60px;
  }
  
  .featuresGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 50px;
  }
  
  .featureCard {
    text-align: center;
    padding: 20px;
  }
  
  .featureIconWrapper {
    width: 64px;
    height: 64px;
    /* background-color: #f8f5ff; */
    border: 0.625px solid var(--White-white-600, #E2E2E2);
    border-radius: 62.5px;
    margin: 0 auto 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .walletIcon,
  .transferIcon,
  .rampIcon,
  .goodsIcon {
    width: 24px;
    height: 24px;
    /* background-color: #6b46c1; */
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
  }
  
  .featureTitle {
    font-size: 20px;
    font-weight: 600;
    color: #1a1a1a;
    margin-bottom: 16px;
  }
  
  .featureDescription {
    font-size: 14px;
    line-height: 1.6;
    color: #666666;
  }
  
  @media (max-width: 1024px) {
    .featuresGrid {
      grid-template-columns: repeat(2, 1fr);
      gap: 40px;
    }
  }
  
  @media (max-width: 640px) {
    .walletFeaturesTitle {
      font-size: 32px;
      margin-bottom: 40px;
    }
  
    .featuresGrid {
      grid-template-columns: 1fr;
      gap: 32px;
    }
  
    .featureCard {
      padding: 16px;
    }
  
    .featureTitle {
      font-size: 18px;
    }
  }
  
  @media (max-width: 480px) {
    .walletFeaturesTitle {
      font-size: 28px;
      padding: 0 20px;
    }
  
    .featureIconWrapper {
      width: 56px;
      height: 56px;
    }
  }