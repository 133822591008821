.sidebar {
    width: 280px;
    /* background: white; */
    padding: 24px;
    border-radius: 12px;
    height: fit-content;
    position: sticky;
    top: 20px;
}

.header {
    color: var(--Text-Dark, #010E1F);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
}

.avatar {
    width: 72px;
    height: 72px;
    background: #8D93E8;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
    font-weight: 600;
}

.tierBadge {
    background: #F8EAFF;
    color: #602182;
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 14px;
    font-weight: 500;
}

.profileHeader {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 32px;
}

.sidebarNav {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.sidebarNav a {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px;
    color: #666;
    text-decoration: none;
    border-radius: 8px;
}

.sidebarNav a img {
    opacity: 0.5; 
}

.sidebarNav a.active {
    color: #00AE5A;
}

.sidebarNav a.active img {
    opacity: 1; 
    filter: invert(48%) sepia(13%) saturate(1352%) hue-rotate(87deg) brightness(95%) contrast(80%);
}

.signOutButton {
    margin-top: 24px;
    width: 100%;
    padding: 12px;
    color: #dc3545;
    background: none;
    border: none;
    cursor: pointer;
    gap: 12px;
    /* text-align: left; */
    display: flex;
    align-items: center;
}
