.paymentContainer {
  display: flex;
  gap: 2rem;
  padding: 2rem; /* Consider increasing this value for more spacing */
  max-width: 1400px; /* Increased max-width for a wider layout */
  margin: 0 auto;
  background: #fff;
  justify-content: flex-start; /* Align items to the left */
}

.paymentMainSection {
  flex: 1;
}

.paymentMainSection h1 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.subtitle {
  color: #666;
  margin-bottom: 2rem;
}

.cartSummary {
  width: 550px; /* Keep this or adjust as needed */
  padding: 2rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 24px 0px rgba(2, 142, 75, 0.10);
  height: fit-content;
}

.summaryTitle {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  color: #000;
}

.couponSection {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 2rem;
}

.couponInput {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.applyButton {
  padding: 0.6rem 0.9rem;
  color: #00AE5A;
  background: white;
  border: 1px solid #00AE5A;
  border-radius: 7px;
  cursor: pointer;
  font-weight: 900;
}

.summaryDetails {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
  border-top: 1px solid #ddd;
}

.summaryRow {
  display: flex;
  justify-content: space-between;
  color: #666;
  padding-top: 5px;
}

.summaryRowr {
  display: grid;
  justify-content: space-between;
  color: #666;
  padding-bottom: 20px;
}

.totalRow {
  color: #663399;
  font-weight: 600;
  border-top: 2px solid #eee;
  padding-top: 1rem;
}

.checkoutButton {
  width: 100%;
  padding: 1rem;
  background: #00AE5A;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .paymentContainer {
      flex-direction: column;
      padding: 1rem;
  }

  .cartSummary {
      width: 100%; /* Ensure full width on smaller screens */
  }
}

@media (max-width: 768px) {
  .cartContainer {
    flex-direction: column;
    padding: 1rem;
    gap: 2rem;
  }

  .cartMainSection {
    width: 100%;
  }

  .cartHeader h1 {
    font-size: 1.75rem;
  }

  .itemCount {
    font-size: 1.25rem;
  }

  .itemImageContainer {
    width: 100px;
    height: 100px;
  }

  .cartSummary {
    width: 100%;
  }

  .toggleDetailsButton {
    background: none;
    border: none;
    color: #00AE5A;
    cursor: pointer;
    font-size: 1rem;
    margin-left: 8rem;
    display: flex;
    align-items: flex-start;
}

.summaryHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggleButton {
  font-size: 0.9rem;
}


.summaryDetails {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
    border-top: 1px solid #ddd;
}

.head {
  display: flex;
}

.totalRow {
  margin-bottom: 20px;
}
}

@media (max-width: 480px) {
  .cartContainer {
    padding: 1rem 0.5rem;
  }

  .cartHeader h1 {
    font-size: 1.5rem;
  }

  .itemCount {
    font-size: 1rem;
  }

  .cartItem {
    padding: 1rem 0;
  }

  .itemImageContainer {
    width: 80px;
    height: 80px;
  }

  .itemName {
    font-size: 1.1rem;
    margin-bottom: -20px;
  }

  .quantityButton, .quantityInput, .deleteButton {
    width: 36px;
    height: 36px;
  }

  .quantityInput {
    width: 40px;
  }

  .quantityButtonimg, .trashIcon {
    width: 20px;
    height: 20px;
  }

  .itemDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
  }

  .itemPrice {
    font-size: 1rem;
    color: #7C3AED;
    margin-top: 0.5rem;
  }

  .rightSection {
    /* display: flex; */
    /* align-items: center; */
    margin-top: 40px;
  }
}

@media (max-width: 360px) {
  .leftSection {
    gap: 0.75rem;
  }

  .itemImageContainer {
    width: 70px;
    height: 70px;
  }

  .itemName {
    font-size: 1rem;
  }
}