.successContainer {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
  }
  
  .successCard {
    background: white;
    border-radius: 16px;
    padding: 2rem;
    width: 100%;
    max-width: 600px;
  }
  
  .successHeader {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .successIcon {
    width: 80px;
    height: 80px;
    margin: 0 auto 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .successIcon img {
    width: 40px;
    height: 40px;
  }
  
  .successHeader h1 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .amountSection {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .amountLabel {
    color: #666;
  }
  
  .amount {
    font-size: 2rem;
    font-weight: 600;
    color: #673AB7;
  }
  
  .transactionInfo {
    color: #666;
    font-size: 0.9rem;
  }
  
  .separator {
    margin: 0 0.5rem;
  }
  
  .detailsSection {
    border-radius: 12px;
    margin-bottom: 2rem;
    border: 1px solid var(--White-white-600, #E2E2E2)
  }
  
  .deets {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    color: #333;
    background: #f8f8f8;
    padding: 12px 16px; /* Remove top and bottom gaps */
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 100%; /* Ensures it fills the parent width */
    border-radius: 8px;
  }
  
  .detailsSection h3 {
    font-size: 1rem;
    color: #666;
    margin: 1.5rem 0 0.5rem;
  }
  
  .itemsList, .summaryList {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem;
  }
  
  .item, .summaryItem {
    display: flex;
    justify-content: space-between;
    color: #666;
  }

  .item {
    display: flex;
    justify-content: space-between;
    color: #666;
    padding-bottom: 0.5rem;
    border-bottom: 1px dotted #ccc;
  }

  .recipientInfo {
    margin: 1.5rem 0;
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    padding: 1.5rem;
  }
  
  
  .recipient {
    display: flex;
    gap: 0.25rem;
    flex-direction: column;
  }

  .recipient span {
    color: #333;
  }
  
  .recipient span:last-child {
    color: #666;
  }

  .font {
    font-weight: 600;
  }
  
  .total {
    border-top: 1px solid #e0e0e0;
    padding-top: 1rem;
    margin-top: 0.5rem;
    font-weight: 600;
    color: #333;
  }
  
  .actions {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 1rem;
  }
  
  .printButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background: white;
    color: #333;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .printButton:hover {
    background: #f5f5f5;
  }
  
  .shopButton {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 8px;
    background: #4CAF50;
    color: white;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .shopButton:hover {
    background: #43A047;
  }
  
  @media (max-width: 576px) {
    .successContainer {
      padding: 1rem;
    }
  
    .successCard {
      padding: 1.5rem;
    }
  
    .actions {
      grid-template-columns: 1fr;
    }
  
    .printButton {
      justify-content: center;
    }
  }
  
  @media print {
    .actions {
      display: none;
    }
  
    .successContainer {
      padding: 0;
    }
  
    .successCard {
      box-shadow: none;
    }
  }